import styled from 'styled-components';

export const StyledSidebar = styled.aside`
  background-color: ${({ theme }) => theme.primary};
  font-size: 0.9rem;

  > div:first-child {
    position: sticky;
    top: 0;
    padding: 2rem;

    ${({ theme }) => theme.onDesktop} {
      display: flex;
      flex-direction: column;
      height: 100vh;

      > div:last-child {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }

  * {
    color: ${({ theme }) => theme.white} !important;
  }

  h3 {
    display: inline;
  }

  p {
    line-height: 1.3;
  }

  ul {
    margin: 0;
    padding: 0;
    padding-top: 1rem;

    li {
      list-style: none;

      a {
        display: flex;
        align-items: center;
        font-weight: 300;
        background: none;
      }
    }
  }

  #cc-info {
    margin-top: 2rem;
    a {
      display: flex;
      background: none;
    }
  }

  .gatsby-image-wrapper {
    margin-bottom: 1.5rem;

    img {
      width: 100%;
      height: auto;
      border-radius: 50%;
    }
  }

  svg {
    height: 1em;
  }

  i,
  svg {
    margin-right: 0.25rem;
  }

  ${({ theme }) => theme.onMobile} {
    .gatsby-image-wrapper {
      height: 120px;
      width: 120px;
      float: right;
      margin: 0 auto 0 1rem;
    }
  }
`;
