import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html, body {
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.textBlack};
    font-family: ${({ theme }) => theme.bodyFont};
    font-size: 18px;
    line-height: 1.8;
    margin: 0;
    
    ${({ theme }) => theme.onMobile} { font-size: 16px; }
    ${({ theme }) => theme.onTablet} { font-size: 17px; }
    ${({ theme }) => theme.onDesktop} { font-size: 18px; }
    ${({ theme }) => theme.onMassiveScreen} { font-size: 19px; }
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
    font-family: inherit;
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${({ theme }) => theme.black};
    font-family: ${({ theme }) => theme.titleFont};
    font-weight: 700;
  }

  h1 {
    font-size: 3rem;
    line-height: 1.05em;

    &.small {
      font-size: 2rem;
      line-height: 1.25em;
    }

    ${({ theme }) => theme.onMobile} {
      font-size: 2rem;
      line-height: 1.25em;

      &.small {
        font-size: 1.5rem;
      }
    }
  }

  h2 {
    font-size: 2.25rem;
    line-height: 1.25em;

    ${({ theme }) => theme.onMobile} {
      font-size: 1.625rem;
      line-height: 1.154em;
    }
  }

  h3 {
    font-size: 1.75rem;
    line-height: 1.25em;

    ${({ theme }) => theme.onMobile} {
      font-size: 1.375rem;
      line-height: 1.364em;
    }
  }

  h4 {
    font-size: 1.125rem;
    line-height: 1.22em;

    ${({ theme }) => theme.onMobile} {
      line-height: 1.11em;
    }
  }


  a, button {
    cursor: pointer;
  }

  a {
    position: relative;
    color: ${({ theme }) => theme.black};
    font-weight: 600;
    text-decoration: none;
  }

  a, .highlight {
    display: inline;
    background-image: linear-gradient(
      to bottom,
      transparent 70%,
      ${({ theme }) => theme.contrastLight} 70%
    );
  }

  a[aria-current="page"], .highlight-on-dark-bg {
    background-image: linear-gradient(
      to bottom,
      transparent 70%,
      ${({ theme }) => theme.contrastDark} 70%
    );
  }

  *:disabled {
    cursor: not-allowed;
  }

  blockquote {
    font-style: italic;
    border-left: 0.3rem solid ${({ theme }) => theme.contrastDark};
    padding: 1px 1rem;
    background-color: ${({ theme }) => theme.offWhite};
    margin: 1rem auto;
    max-width: 70ch;
  }

  cite {
    display: flex;
    justify-content: flex-end;

    &::before {
      content: "—";
      padding-right: 0.5rem;
    }
  }

  #___gatsby > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
  }

  body.four-oh-four {
    background-color: ${({ theme }) => theme.primary};
  }

  img {
    max-width: 100%;
    height: auto;
  }

  strong {
    font-weight: 600;
  }

  pre, code {
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.white};
    font-family: monospace;
    font-size: 0.8rem;
    padding: 0 0.2rem;
  }

  ul {
    ${({ theme }) => theme.onTablet} {
      padding-left: 1.5rem;
    }
  }
`;
