import styled from 'styled-components';
import posed from 'react-pose';
import { pageFade } from 'style/poses';

const div = posed.div(pageFade);

export const PageFade = styled(div)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  will-change: opacity;
`;

export const LayoutGrid = styled.div`
  display: flex;
  flex-direction: column-reverse;
  flex: 1 0 auto;

  ${({ theme }) => theme.onDesktop} {
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-template-rows: 100%;
  }
`;
