import React, { Component } from 'react';
import { object } from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Link from 'components/Link';
import Image from 'components/PreviewCompatibleImage';
import { StyledSidebar } from './styled';
import OrcidIcon from './OrcidIcon';

class Sidebar extends Component {
  static propTypes = {
    profilePic: object.isRequired,
  };

  render() {
    const { profilePic } = this.props;

    return (
      <StyledSidebar>
        <div>
          <Image imageInfo={profilePic} alt="Rute Correia" />
          <div>
            <Link to="/" className="highlight-on-dark-bg">
              <h3>Rute Correia</h3>
            </Link>
            <p>
              PhD candidate in Communication at ISCTE-IUL, radio person,
              activist
            </p>
          </div>

          <div>
            <ul>
              <li>
                <i className="fas fa-fw fa-map-marker-alt" aria-hidden="true" />
                Lisbon, Portugal
              </li>

              <li>
                <Link to="https://twitter.com/ruteradio">
                  <i className="fab fa-fw fa-twitter" aria-hidden="true" />
                  Twitter
                </Link>
              </li>

              <li>
                <Link to="https://github.com/aariops">
                  <i className="fab fa-fw fa-github" aria-hidden="true" />
                  Github
                </Link>
              </li>

              <li>
                <Link to="https://orcid.org/0000-0003-3875-8215">
                  <OrcidIcon />
                  ORCID
                </Link>
              </li>
            </ul>
            <div id="cc-info">
              <Link
                to="https://creativecommons.org/licenses/by-sa/4.0/"
                title="Licensed under Creative Commons Attribution-ShareAlike 4.0 International"
                aria-label="Licensed under Creative Commons Attribution-ShareAlike 4.0 International"
              >
                <i className="fab fa-2x fa-creative-commons" />
                <i className="fab fa-2x fa-creative-commons-by" />
                <i className="fab fa-2x fa-creative-commons-sa" />
              </Link>
            </div>
          </div>
        </div>
      </StyledSidebar>
    );
  }
}
const query = graphql`
  query ProfilePicQuery {
    profilePic: file(relativePath: { eq: "profile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 180, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default (props) => (
  <StaticQuery
    query={query}
    render={(data) => <Sidebar {...data} {...props} />}
  />
);
