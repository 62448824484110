const duration = 150;
export const delay = duration * 1.5;
const stagger = duration / 2;
const ease = `easeInOut`;

export const pageFade = {
  initial: {
    opacity: 0,
    y: 20,
  },
  enter: {
    delay,
    beforeChildren: true,
    opacity: 1,
    transition: { duration, ease },
    y: 0,
  },
  exit: {
    opacity: 0,
    transition: { duration, ease },
    y: -20,
  },
};

export const staggerChildren = {
  enter: { staggerChildren: stagger },
};

export const delayChildren = {
  enter: { delayChildren: delay },
};

export const slideUp = {
  initial: { opacity: 0, y: 10 },
  enter: {
    opacity: 1,
    y: 0,
    transition: { duration },
  },
};

export const slideUpWithDelay = {
  ...slideUp,
  enter: { ...slideUp.enter, delay },
};

export const fade = {
  initial: { opacity: 0 },
  enter: {
    opacity: 1,
    transition: { duration },
  },
};

export const fadeWithDelay = { ...fade, enter: { ...fade.enter, delay } };
