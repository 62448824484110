import styled from 'styled-components';
import Link from 'components/Link';

export const Nav = styled.nav`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 900;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  padding: 0;
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Menu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const MenuItem = styled.li`
  margin: 0 auto;
  line-height: 1;
  padding: 1rem;
`;

export const NavLink = styled(Link)`
  font-size: 1.5rem;
  font-weight: 600;
`;
