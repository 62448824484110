import React, { Component } from 'react';
import { node, object } from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { PoseGroup } from 'react-pose';
import 'typeface-karla';
import 'typeface-rubik';
import Navbar from 'components/Navbar';
import Sidebar from 'components/Sidebar';
import { TopLevelHelmet } from 'components/Helmets';
import GlobalStyle from 'style/global';
import theme from 'style/theme';
import { PageFade, LayoutGrid } from './styled';

class TemplateWrapper extends Component {
  static propTypes = {
    children: node.isRequired,
    location: object.isRequired,
  };

  state = {
    mounted: false,
  };

  componentDidMount() {
    this.setState({ mounted: true });
  }

  render() {
    const { children, location = {} } = this.props;
    const { mounted } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyle />
          <TopLevelHelmet />

          <LayoutGrid>
            <Sidebar />
            <div>
              <Navbar locationKey={location.key} />

              <PoseGroup
                animateOnMount
                preEnterPose={mounted ? `initial` : `enter`}
              >
                <PageFade key={location.pathname}>
                  <main>{children}</main>
                </PageFade>
              </PoseGroup>
            </div>
          </LayoutGrid>
        </>
      </ThemeProvider>
    );
  }
}

export default TemplateWrapper;
