export function hexToRGBA(hex, alpha) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
}

const white = `#FFFFFF`;
const contrastLight = `#ffd2dd`;
const contrastDark = `#e76f8c`;
const primaryLight = `#597382`;
const offWhite = `#F2F2F2`;
const lightGrey = `#E9E9E9`;
const grey = `#C3B1AA`;
const textBlack = `#3E515F`;
const primary = `#0A2333`;
const black = `#040F17`;

export default {
  white,
  contrastLight,
  contrastDark,
  primaryLight,
  primary,
  offWhite,
  lightGrey,
  grey,
  textBlack,
  black,
  seeThruWhite: hexToRGBA(white, 0.8),
  seeThruBlack: hexToRGBA(black, 0.7),
  seeThruPrimary: hexToRGBA(primary, 0.7),
  seeThruPrimaryLight: hexToRGBA(primaryLight, 0.5),
};
