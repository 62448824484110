import React, { Component } from 'react';
import { LINKS } from './constants';
import { Menu, MenuItem, Nav, NavLink } from './styled';

class Navbar extends Component {
  render() {
    return (
      <Nav>
        <Menu>
          {LINKS.map(({ page, url }) => (
            <MenuItem key={url}>
              <NavLink to={url}>{page}</NavLink>
            </MenuItem>
          ))}
        </Menu>
      </Nav>
    );
  }
}

export default Navbar;
